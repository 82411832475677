import React,{useEffect} from 'react';
import {Link, useLocation} from "react-router-dom";

const NavKargoLink = () => {

    const navegacion = useLocation()

    return (
        <ul className="mainmenu">
            <li>
                <Link to="/" style={navegacion.pathname === "/" ? {fontWeight: "800"} : {}}>Inicio</Link>
            </li>
            <li>
                <Link to="/exportador" style={navegacion.pathname === "/exportador" ? {fontWeight: "800"} : {}}>Para Exportadores/Importadores</Link>
            </li>
            
            <li><Link to="/proveedor" style={navegacion.pathname === "/proveedor" ? {fontWeight: "800"} : {}}>Para Proveer Servicios Logísticos</Link></li>

            <li><Link to="/contact" style={navegacion.pathname === "/contact" ? {fontWeight: "800"} : {}}>Contacto</Link></li>
        </ul>
    )
}
export default NavKargoLink;