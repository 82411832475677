import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import imgOne from "../../assets/images/kargolink/trust.svg"
import imgTwo from "../../assets/images/kargolink/canal-de-venta.svg"
import imgThree from "../../assets/images/kargolink/oportunidades.svg"

const AdvanceTabOne = () => {
    return (
        <div>
            <Tabs>
                <div className="row row--30">
                    <div className="order-2 order-lg-1 col-lg-4 col-md-12 col-sm-12 col-12 mt_md--30 mt_sm--30">
                        <div className="advance-tab-button advance-tab-button-1">
                            <TabList className="tab-button-list">
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">Sólo empresas verificadas.</h4>
                                        <p className="description">Queremos crear un espacio seguro para negociaciones solo con <strong>empresas verificadas</strong>.</p>
                                    </div>   
                                </Tab>
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">Nuevo canal de comunicación.</h4>
                                        <p className="description">Tiempos modernos requieren herramientas modernas.</p>
                                    </div>   
                                </Tab>
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">Oportunidades a tu alcance.</h4>
                                        <p className="description">Haz que tu empresa sea visible con otras empresas que te <strong>beneficien</strong>.</p>
                                    </div>   
                                </Tab>
                            </TabList>
                        </div>
                    </div>
                    <div className="order-1 order-lg-2 col-lg-8 col-md-12 col-sm-12 col-12">
                        <div className="advance-tab-content advance-tab-content-1">
                            <TabPanel>
                                <div className="tab-content">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <img src={imgOne} alt="Corporate Images" />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            
                            <TabPanel>
                                <div className="tab-content">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <img src={imgTwo} alt="Corporate Images" />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="tab-content">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <img src={imgThree} alt="Corporate Images" />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                        </div>
                    </div>
                </div>
            </Tabs>
        </div>
    )
}
export default AdvanceTabOne;