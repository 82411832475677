import React from 'react';
import SEO from "./common/SEO";
import FooterThree from './common/footer/FooterThree';
import Typed from 'react-typed';
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import ServiceFour from './elements/service/ServiceFour';
import AdvanceTabOne from './elements/advancetab/AdvanceTabOne';
import BrandTwo from './elements/brand/BrandTwo';
import TimelineOne from './elements/timeline/TimelineOne';
import Separator from "./elements/separator/Separator";
import SectionTitle from "./elements/sectionTitle/SectionTitle";
import HeaderKargoLink from './components-kargolink/HeaderKargoLink';
import imgBanner from "./assets/images/kargolink/banner.webp"
import CalltoActionEight from './elements/calltoaction/CalltoActionEight';
import exportadorImg from "./assets/images/kargolink/exportador-timeline.webp"
import proveedorImg from "./assets/images/kargolink/proveedor-timeline.webp"

const ServiceList = [
    {
        icon: '1',
        title: 'Empresa publica carga',
        description: 'De cualquier tipo.'
    },
    {
        icon: '2',
        title: 'Proveedor encuentra cargas',
        description: 'Prefiltramos oportunidades interesantes para cada perfil.'
    },
    {
        icon: '3',
        title: 'Proveedor cotiza',
        description: 'Crear cotizaciones para clientes que ya requieren el servicio.'
    },
    {
        icon: '4',
        title: 'Empresa recibe cotización',
        description: 'Recibe cotizaciones solo de perfiles capacitados.'
    },
]

const TimelineData = [
    {
        id: "1",
        date: "Quiero enviar carga",
        title: "Para exportadores o importadores.",
        description: "Regístrate y publica lo que necesites enviar.",
        image: exportadorImg,
        workingStep: [
            {
                stepTitle: "Cargas recurrentes",
                stepDescription: "Puede ser desde una simple ocasión o un evento recurrente",
            },
            {
                stepTitle: "Licitaciones",
                stepDescription: "Facilita tu proceso de licitación publicando tus concursos de servicio",
            },
        ],
        buttonText: "Entrar como exportador",
        buttonLink: "/exportador"
    },
    {
        id: "2",
        date: "Quiero proveer servicios logísticos",
        title: "Para proveedores.",
        description: "Accede a un nuevo canal de venta, con empresas que ya tienen la necesidad de un proveedor.",
        image: proveedorImg,
        workingStep: [
            {
                stepTitle: "Descubre cargas",
                stepDescription: "Acceso directo a pedidos de empresas que sean de tu interés",
            },
            {
                stepTitle: "Envía cotizaciones",
                stepDescription: "Ofrece tus servicios a empresas verificadas.",
            },
        ],
        buttonText: "Entrar como proveedor",
        buttonLink: "/proveedor"
    },
]

const KargoLink = () => {
    return (
        <>
            <SEO title="Inicio" />
            <main className="page-wrapper">
                
                <HeaderKargoLink btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />


                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 bg-transparent variation-2 height-750">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 order-2 order-lg-1 mt_md--40 mt_sm--40">
                                <div className="inner text-start">
                                    <h1 className="title theme-gradient display-two">Conectamos <br />
                                        <Typed
                                            strings={[
                                                "Exportadores.",
                                                "Forwarders.",
                                                "Transportistas.",
                                                "Licitaciones.",
                                                "Cargas.",
                                            ]}
                                            typeSpeed={80}
                                            backSpeed={5}
                                            backDelay={1000}
                                            loop
                                        />
                                    </h1>
                                    <p className="description">Somos un marketplace de servicios logísticos. Juntamos empresas con proveedores de logística.</p>
                                    <div className="button-group">
                                        <Link className="btn-default btn-medium btn-border round btn-icon" to="/exportador">Quiero enviar carga<i className="icon"><FiArrowRight /></i></Link>
                                        <Link className="btn-default btn-medium btn-border round btn-icon" to="/proveedor">Quiero proveer servicios<i className="icon"><FiArrowRight /></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 order-1 order-lg-2">
                                <div className="thumbnail">
                                    <img src={imgBanner} alt="Banner Images" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "¿Qué es kargolink?"
                                    title = "Encuentra proveedores logísticos. Encuentra cargas para mandar."
                                    description = "Abrimos un canal de comunicación directo entre empresas, <br /> permitiendo cerrar <strong>mejores contratos de carga</strong>."
                                    />
                            </div>
                        </div>
                        <ServiceFour 
                            serviceStyle = "service__style--1 icon-circle-style with-working-process"
                            textAlign = "text-center"
                            serviceList={ServiceList}
                            />
                    </div>
                </div>
                {/* End Service Area  */}


                <Separator />
                {/* Start Timeline Area  */}
                <div className="rwt-timeline-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Elige como usar kargolink"
                                        title = "¿Quieres enviar o quieres proveer el servicio?"
                                        description = "Crea una cuenta para ser exportador<br />  o proveer servicios logísticos."
                                    />
                            </div>
                        </div>
                        <div className="row mt_dec--20">
                            <div className="col-lg-12">
                                <TimelineOne timeLineData={TimelineData}/>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Timeline Area  */}

                <Separator />
                {/* Start Elements Area  */}
                <div className="rwt-advance-tab-area rn-section-gap">
                    <div className="container">
                        <div className="row mb--40">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Expande tu red de contactos."
                                    title = "¿Qué ofrecemos?"
                                    description = "Brindamos una herramienta digital para simplificar búsquedas, <br /> obtén una visión extendida de lo que hay en el mercado."
                                />
                            </div>
                        </div>
                        <AdvanceTabOne />
                    </div>
                </div>
                {/* End Elements Area  */}

                

                <Separator />

                {/* Precio por definir */}
                {/* <div className="rwt-pricingtable-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Our Pricing Table"
                                    title = "Advance Pricing Table."
                                    description = "There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration."
                                />
                            </div>
                        </div>
                        <div className="row mt--30">
                            <div className="col-lg-8 offset-lg-2">
                                <AdvancePricingOne />
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End Elements Area  */}

                {/* Start Brand Area  */}
                <div className="rwt-brand-area rn-section-gapBottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Empresas que ya confian en Kargolink"
                                    title = "Únete a esta nueva comunidad de logística"
                                    description = ""
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 mt--10">
                                <BrandTwo brandStyle="brand-style-2" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area  */}

                <CalltoActionEight/>

                <FooterThree />

            </main>
        </>
    )
}

export default KargoLink;