import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const AdvanceTabTwo = ({data}) => {
    return (
        <div>
            <Tabs>
                <div className="row row--30">
                    <div className="col-lg-8">
                        <div className="advance-tab-content advance-tab-content-1">
                            {
                                data.map(d => (
                                    <TabPanel key={d.id}>
                                        <div className="tab-content">
                                            <div className="inner">
                                                <div className="thumbnail">
                                                    <img src={d.image} alt="Corporate Images" />
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                ))
                            }
                        </div>
                    </div>
                    <div className="col-lg-4 mt_md--30 mt_sm--30">
                        <div className="advance-tab-button advance-tab-button-1 right-align">
                            <TabList className="tab-button-list">
                                {
                                    data.map(d => (
                                        <Tab key={d.id}>
                                            <div className="tab-button">
                                                <h4 className="title">{d.title}</h4>
                                                <p className="description">{d.desc}</p>
                                            </div>   
                                        </Tab>
                                    ))
                                }
                                {/* <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">Centraliza tus solicitudes de carga.</h4>
                                        <p className="description">Organiza tu trabajo de búsqueda con nuestras pantallas para manejo de solicitudes y cotizaciones.</p>
                                    </div>   
                                </Tab>
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">Proveedores capaces.</h4>
                                        <p className="description">Si necesitas que tus proveedores cumplan con alguna certificación o servicio lo puedes pedir a la hora de hacer una solicitud de carga.</p>
                                    </div>   
                                </Tab>
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">Comunicación segura.</h4>
                                        <p className="description">Los proveedores no podrán ver tu información a menos que aceptes una cotización de ellos.</p>
                                    </div>   
                                </Tab> */}
                            </TabList>
                        </div>
                    </div>
                    
                </div>
            </Tabs>
        </div>
    )
}
export default AdvanceTabTwo;
