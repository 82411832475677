import React from 'react';
import SEO from "./common/SEO";
import FooterThree from './common/footer/FooterThree';
import Typed from 'react-typed';
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import ServiceFour from './elements/service/ServiceFour';
import AdvanceTabOne from './elements/advancetab/AdvanceTabOne';
import BrandTwo from './elements/brand/BrandTwo';
import TimelineOne from './elements/timeline/TimelineOne';
import Separator from "./elements/separator/Separator";
import SectionTitle from "./elements/sectionTitle/SectionTitle";
import HeaderKargoLink from './components-kargolink/HeaderKargoLink';
import imgBanner from "./assets/images/kargolink/exportador-timeline.webp"
import { FiCheck } from 'react-icons/fi';
import CalltoActionFive from './elements/calltoaction/CalltoActionFive';
import AdvanceTabTwo from './elements/advancetab/AdvanceTabTwo';
import exportadorImg from "./assets/images/kargolink/exportador-timeline.webp"
import proveedorImg from "./assets/images/kargolink/proveedor-timeline.webp"

const ServiceList = [
    {
        icon: '1',
        title: 'Registra tu empresa',
        description: 'Detalla tu cobertura, servicios y certificaciones.'
    },
    {
        icon: '2',
        title: 'Descubre cargas',
        description: 'Dependiendo tus servicios mostraremos cargas disponibles que te podría interesar atender.'
    },
    {
        icon: '3',
        title: 'Envía cotizaciones',
        description: 'Sólo las empresas solicitantes podrán ver todo lo que ofreces.'
    },
    {
        icon: '4',
        title: 'Obtén nuevos clientes',
        description: 'Crea contactos por esta nueva herramienta digital.'
    },
]

const TimelineData = [
    {
        id: "1",
        date: "Por evento",
        title: "Cargas recurrentes o no recurrentes.",
        description: "Elige los servicios o características que necesites.",
        image: exportadorImg,
        workingStep: [
            {
                stepTitle: "Detalla tu carga",
                stepDescription: "Ingresa origen, destino, medidas, tipo de mercancía.",
            },
            {
                stepTitle: "Recomendaciones",
                stepDescription: "Sigue los tips que se muestran durante la creación de la carga",
            },
        ],
        buttonText: "Ver demo"
    },
    {
        id: "2",
        date: "Por contrato",
        title: "Licitaciones.",
        description: "Muestra tus concursos solo a proveedores calificados para el trabajo.",
        image: proveedorImg,
        workingStep: [
            {
                stepTitle: "Detalla la licitación",
                stepDescription: "Puedes anexar archivos en caso de requerir detalles adicionales o formatos a llenar",
            },
            {
                stepTitle: "Recomendaciones",
                stepDescription: "Sigue los tips que se muestran durante la creación de la licitación",
            },
        ],
        buttonText: "Ver demo"
    },
]

const beneficios = [
    {
        id: 1,
        image: exportadorImg,
        title: "Centraliza tus cotizaciones de carga.",
        desc: "Organiza tu trabajo de búsqueda con nuestras pantallas para manejo de solicitudes y cotizaciones."
    },
    {
        id: 2,
        image: exportadorImg,
        title: "Nuevo canal de venta.",
        desc: "Accede directamente a una lista de empresas que ya necesitan ser atendidos."
    },
    {
        id: 3,
        image: exportadorImg,
        title: "Comunicación segura.",
        desc: "Las empresas no podrán ver tu información a menos que te acepten una cotización."
    }
]

const KargoLinkProveedor = () => {
    return (
        <>
            <SEO title="Exportador" />
            <main className="page-wrapper">
                
                <HeaderKargoLink btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />


                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 bg-transparent height-750">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-7 col-xl-6 order-2 order-lg-1 mt_md--40 mt_sm--40">
                                <div className="inner text-start">
                                    <span className="subtitle">Para proveedores</span>
                                    <h1 className="title theme-gradient display-one">Aumenta tu flujo de trabajo.</h1>
                                    <ul className="list-icon">
                                        <li><span className="icon"><FiCheck /></span> Registra tus servicios y cobertura.</li>
                                        <li><span className="icon"><FiCheck /></span> Encuentra cargas.</li>
                                        <li><span className="icon"><FiCheck /></span> Conecta con nuevos clientes.</li>
                                    </ul>
                                    <div className="button-group mt--40">
                                        <a className="btn-default btn-medium round btn-icon" target="_blank" href="https://app.kargo-link.com/login-proveedor">Entrar ahora <i className="icon"><FiArrowRight /></i></a>
                                        <Link className="btn-default btn-medium btn-border round btn-icon" to="#">Contacta a un agente <i className="icon"><FiArrowRight /></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 col-xl-6 order-1 order-lg-2">
                                <div className="frame-image">
                                    <img src={imgBanner} alt="Banner Images" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Para proveedores"
                                    title = "Encuentra nuevas cargas."
                                    description = "Descubre un nuevo <strong>canal de venta</strong><br />con empresas que ya necesitan tus servicios."
                                    />
                            </div>
                        </div>
                        <ServiceFour 
                            serviceStyle = "service__style--1 icon-circle-style with-working-process"
                            textAlign = "text-center"
                            serviceList={ServiceList}
                            />
                    </div>
                </div>
                {/* End Service Area  */}

                <Separator />
                {/* Start Elements Area  */}
                <div className="rwt-advance-tab-area rn-section-gap">
                    <div className="container">
                        <div className="row mb--40">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Expande tu red de contactos."
                                    title = "Eficientiza la búsqueda de nuevos clientes"
                                    description = "Seguridad para hacer negocios por internet. <br /> Cada exportador pasa un proceso de verificación."
                                />
                            </div>
                        </div>
                        <AdvanceTabTwo data={beneficios}/>
                    </div>
                </div>
                {/* End Elements Area  */}

                <Separator />
                {/* Start Timeline Area  */}
                <div className="rwt-timeline-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Elige como usar kargolink"
                                        title = "Tu eliges que publicar"
                                        description = "Puede ser desde una carga <br />  hasta concursos para <strong>licitaciones</strong>."
                                    />
                            </div>
                        </div>
                        <div className="row mt_dec--20">
                            <div className="col-lg-12">
                                <TimelineOne timeLineData={TimelineData}/>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Timeline Area  */}

                

                

                <Separator />

                {/* Precio por definir */}
                {/* <div className="rwt-pricingtable-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Our Pricing Table"
                                    title = "Advance Pricing Table."
                                    description = "There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration."
                                />
                            </div>
                        </div>
                        <div className="row mt--30">
                            <div className="col-lg-8 offset-lg-2">
                                <AdvancePricingOne />
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End Elements Area  */}

                {/* Start Brand Area  */}
                <div className="rwt-brand-area rn-section-gapBottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Empresas que ya confian en Kargolink"
                                    title = "Únete a esta nueva comunidad de logística"
                                    description = ""
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 mt--10">
                                <BrandTwo brandStyle="brand-style-2" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area  */}

                <CalltoActionFive/>
                <Separator />
                <Separator />
                <Separator />
                <Separator />
                <FooterThree />

            </main>
        </>
    )
}

export default KargoLinkProveedor;